export const painArray = [
    'oof ouch owie, bone hurting clicks',
    'Why are you like this?',
    `You can't hurt me, Jack`,
    'Pathetic',
    'That tickles',
    'Was that supposed to hurt?',

]
export const timeArray = [
    `You've been here a while`,
    `Not bored yet?`
]